import React from "react";
import { Link } from "gatsby";
import image from './img/BayAreaCoupon.png';

// Helper function to calculate the last day of the current month
const getLastDayOfCurrentMonth = () => {
  const now = new Date();
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  return lastDay.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });
};

const TanklessWaterHeaterPromotion = () => (
  <div
    className={`rounded-lg mb-2 shadow bg-white`}
    style={{
      background: "linear-gradient(145deg, #17a34a 50%, #F4F9FE 50%)",
    }}
  >
    <div className="px-8 pt-6">
      <h4 className="text-white font-bold text-3xl md:text-4xl">
        $1,500 OFF
      </h4>
      <h3 className="text-white font-bold text-lg md:text-xl">
        Whole House Siding Replacement
      </h3>
      <Link
        to={'/offers/siding-replacement-special-offer/'}
        className="button-active-bhvr my-2 inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700 focus:outline-none px-5 py-3 rounded-full bg-white shadow font-display"
      >
        Claim Offer
      </Link>
    </div>

    <div className="px-8 py-3">
      <img 
        src={image}
        alt="Navien Tankless Water Heaters New Jersey"
      />  
      <p className="text-sm text-center text-gray-500 py-2 italic">
        *Offer expires {getLastDayOfCurrentMonth()}
      </p>
    </div>
  </div>
);

export default TanklessWaterHeaterPromotion;
